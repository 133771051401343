<template>
  <div
    class="modal fade"
    :id="
      tipo_control == 1 ? 'modal-form-liquidacion' : 'modal-form-liquidacionR'
    "
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
    style="background-color: #00000082"
    data-backdrop="static"
    data-keyboard="false"
  >
    <div v-if="viaje" class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header bg-frontera-top-left pt-2 pb-2">
          <h4 class="modal-title text-white">
            Liquidación para Despacho Crudos y Derivados
            <small>(Viaje - {{ viaje.id }})</small>
          </h4>
          <button
            type="button"
            class="close text-white"
            data-dismiss="modal"
            aria-label="Close"
            id="close-modal"
            ref="closeModalLiquidacion"
            @click="closeModal()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div
                class="alert alert-default-warning alert-dismissible border border-warning"
              >
                <h5>
                  <i class="icon fas fa-exclamation-triangle"></i> Atención!
                </h5>
                La liquidación solo se puede realizar una vez. <br />
                Le recomendamos que revise cuidadosamente todos los detalles
                antes de solicitar la liquidación para asegurar de que toda la
                información sea correcta y esté completa.
              </div>
              <div class="info-box bg-light">
                <div v-if="form.campo" class="info-box-content">
                  <span class="info-box-text text-center text-muted"
                    >Sitio</span
                  >
                  <span class="info-box-number text-center text-muted mb-0">{{
                    form.campo
                  }}</span>
                </div>
                <div v-if="form.vehiculo" class="info-box-content">
                  <span class="info-box-text text-center text-muted"
                    >Vehículo</span
                  >
                  <span class="info-box-number text-center text-muted mb-0">
                    {{ form.vehiculo }}
                  </span>
                </div>

                <div v-if="form.vehiculo" class="info-box-content">
                  <span class="info-box-text text-center text-muted"
                    >Peso Vehículo</span
                  >
                  <span class="info-box-number text-center text-muted mb-0">
                    {{ form.peso_vehiculo }}
                  </span>
                </div>
                <div v-if="form.remolque" class="info-box-content">
                  <span class="info-box-text text-center text-muted"
                    >Remolque</span
                  >
                  <span class="info-box-number text-center text-muted mb-0">
                    {{ form.remolque }}
                  </span>
                </div>
                <div v-if="form.remolque" class="info-box-content">
                  <span class="info-box-text text-center text-muted"
                    >Peso Remolque</span
                  >
                  <span class="info-box-number text-center text-muted mb-0">
                    {{ form.peso_remolque }}
                  </span>
                </div>
                <div v-if="form.transportadora" class="info-box-content">
                  <span class="info-box-text text-center text-muted"
                    >Transportadora</span
                  >
                  <span class="info-box-number text-center text-muted mb-0">
                    {{ form.transportadora }}
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <div class="row justify-content-center">
                    <div class="form-group col-md-10">
                      <label for="">Sellos</label>
                      <v-select
                        class="form-control form-control-sm col-md-12 p-0"
                        :class="[
                          $v.sellos.$invalid ? 'is-invalid' : 'is-valid',
                          $store.getters.getDarkMode ? 'dark-vselect' : '',
                        ]"
                        v-model="sellos"
                        label="numero"
                        :options="listasForms.sellos"
                        style="font-size: 13px; background-color: #fff"
                        @input="buscarSellos()"
                        placeholder="Sellos"
                        multiple
                      ></v-select>
                    </div>
                    <div class="form-group col-md-10">
                      <label for="">Tanques</label>
                      <v-select
                        class="form-control form-control-sm col-md-12 p-0"
                        label="tanque"
                        v-model="tanque"
                        placeholder="Tanques"
                        :options="tanques"
                        style="font-size: 13px; background-color: #fff"
                        @input="selectTanque()"
                        :class="[
                          $v.form.tanque_id.$invalid
                            ? 'is-invalid'
                            : 'is-valid',
                          $store.getters.getDarkMode ? 'dark-vselect' : '',
                        ]"
                      ></v-select>
                    </div>
                    <div class="form-group col-md-5">
                      <label>Llamado Cargue</label>
                      <div class="row">
                        <div class="col-7">
                          <input
                            type="date"
                            class="form-control"
                            v-model="form.fecha_llamado_cargue"
                            :class="
                              $v.form.fecha_hora_llamado_cargue.$invalid ||
                              $v.form.hora_llamado_cargue.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            @input="getFechaHoraCargue"
                          />
                        </div>
                        <div class="col-5">
                          <input
                            type="text"
                            class="form-control"
                            v-model="form.hora_llamado_cargue"
                            :class="
                              $v.form.fecha_hora_llamado_cargue.$invalid ||
                              $v.form.hora_llamado_cargue.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            v-mask="{
                              mask: '99:99',
                              hourFormat: '24',
                            }"
                            @input="getFechaHoraCargue"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-md-5">
                      <label>Inicio Cargue</label>
                      <div class="row">
                        <div class="col-md-7">
                          <input
                            type="date"
                            class="form-control"
                            v-model="form.fecha_inicio_cargue"
                            :class="
                              $v.form.fecha_hora_inicio_cargue.$invalid ||
                              $v.form.hora_inicio_cargue.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            @input="getFechaHoraInicioCargue"
                          />
                        </div>
                        <div class="col-md-5">
                          <input
                            type="text"
                            class="form-control"
                            v-model="form.hora_inicio_cargue"
                            :class="
                              $v.form.fecha_hora_inicio_cargue.$invalid ||
                              $v.form.hora_inicio_cargue.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            v-mask="{
                              mask: '99:99',
                              hourFormat: '24',
                            }"
                            @input="getFechaHoraInicioCargue"
                          />
                        </div>
                      </div>
                    </div>
                    <div v-if="form.espera_cargue" class="text-center col-md-4">
                      <span>Espera Cargue: </span>
                      <strong
                        >{{ form.espera_cargue }} <small>(Horas)</small></strong
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-lg-6 col-md-12 col-sm-12"
              :class="form.tipo_liquidacion != 1 ? 'offset-3' : ''"
            >
              <div class="card card-light">
                <div class="card-header">
                  <h3 class="card-title">
                    <i class="fas fa-search"></i>
                    Parámetros de búsqueda para liquidación
                  </h3>
                </div>
                <div class="card-body">
                  <!-- Tipo Liquidacion -->
                  <div class="row justify-content-between mb-2">
                    <span class="col-md-4">Tipo Liquidación</span>
                    <select
                      class="form-control form-control-sm col-md-8"
                      v-model="form.tipo_liquidacion"
                      @change="getProductosHidroarburosLiquidación()"
                      :class="
                        $v.form.tipo_liquidacion.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="tipo_liquidacion in listasForms.tipos_liquidacion"
                        :key="tipo_liquidacion.numeracion"
                        :value="tipo_liquidacion.numeracion"
                      >
                        {{ tipo_liquidacion.descripcion }}
                      </option>
                    </select>
                  </div>
                  <!-- Tipo Producto -->
                  <div class="row justify-content-between mb-2">
                    <span class="col-md-4">Tipo Producto</span>
                    <v-select
                      v-model="form.slct_producto"
                      :class="[
                        $v.form.slct_producto.$invalid
                          ? 'is-invalid'
                          : 'is-valid',
                        $store.getters.getDarkMode ? 'dark-vselect' : '',
                      ]"
                      @input="getProductoLiquido()"
                      placeholder="Producto"
                      label="descripcion"
                      class="form-control form-control-sm col-md-8 p-0"
                      :options="listasForms.productos"
                    ></v-select>
                  </div>
                  <!-- Producto -->
                  <div
                    class="row justify-content-between mb-2"
                    v-if="
                      this.form.slct_producto &&
                        (this.form.slct_producto.numeracion == 1 ||
                          this.form.slct_producto.numeracion == 5)
                    "
                  >
                    <span class="col-md-4">Producto</span>
                    <v-select
                      v-model="form.producto_liquido"
                      :class="[
                        $v.form.producto_liquido.$invalid
                          ? 'is-invalid'
                          : 'is-valid',
                        $store.getters.getDarkMode ? 'dark-vselect' : '',
                      ]"
                      placeholder="Producto"
                      @input="llenarValoresProducto()"
                      label="nombre"
                      class="form-control form-control-sm col-md-8 p-0"
                      :options="listasForms.productos_liquidos"
                    ></v-select>
                  </div>
                  <!-- Peso Remolque + Vehículo -->
                  <div
                    class="row justify-content-between mb-2"
                    v-show="form.tipo_liquidacion == 3"
                  >
                    <span class="col-md-8">Peso Inicial</span>
                    <input
                      type="number"
                      class="form-control form-control-sm col-md-4"
                      v-model="form.peso_inicial"
                      :class="
                        $v.form.peso_inicial.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      @input="pesoNeto()"
                    />
                  </div>
                  <!-- Final -->
                  <div
                    class="row justify-content-between mb-2"
                    v-show="form.tipo_liquidacion == 3"
                  >
                    <span class="col-md-8">Peso Final</span>
                    <input
                      type="number"
                      class="form-control form-control-sm col-md-4"
                      v-model="form.peso_final"
                      :class="
                        $v.form.peso_final.$invalid ? 'is-invalid' : 'is-valid'
                      "
                      @input="pesoNeto()"
                    />
                  </div>
                  <!-- Peso Neto -->
                  <div
                    class="row justify-content-between mb-2"
                    v-show="form.tipo_liquidacion == 3"
                  >
                    <span class="col-md-8">Peso Neto</span>
                    <input
                      type="number"
                      class="form-control form-control-sm col-md-4"
                      v-model="form.peso_neto"
                      :class="
                        $v.form.peso_neto.$invalid ? 'is-invalid' : 'is-valid'
                      "
                    />
                  </div>
                  <!-- Api -->
                  <div class="row justify-content-between mb-2">
                    <span class="col-md-8">API Gravity@60F=</span>
                    <input
                      type="number"
                      class="form-control form-control-sm col-md-4"
                      v-model="form.api"
                      :class="$v.form.api.$invalid ? 'is-invalid' : 'is-valid'"
                      @blur="validarApi"
                    />
                  </div>
                  <!-- Temperatura Inicial / Final -->
                  <div
                    class="row justify-content-between mb-2"
                    v-show="form.tipo_liquidacion == 2"
                  >
                    <span class="col-md-4">Temperatura °F =</span>
                    <div class="input-group col-md-4">
                      <div class="input-group-prepend">
                        <div class="input-group-text">Ini</div>
                      </div>
                      <input
                        type="number"
                        class="form-control form-control-sm"
                        v-model="form.temp_ini"
                        :class="
                          $v.form.temp_ini.$invalid ? 'is-invalid' : 'is-valid'
                        "
                        @blur="validarTemp"
                      />
                    </div>

                    <div class="input-group col-md-4">
                      <div class="input-group-prepend">
                        <div class="input-group-text">Fin</div>
                      </div>
                      <input
                        type="number"
                        class="form-control form-control-sm"
                        v-model="form.temp_fin"
                        :class="
                          $v.form.temp_fin.$invalid ? 'is-invalid' : 'is-valid'
                        "
                        @blur="validarTemp"
                      />
                    </div>
                  </div>
                  <!-- Temperatura -->
                  <div
                    class="row justify-content-between mb-2"
                    v-show="form.tipo_liquidacion != 2"
                  >
                    <span class="col-md-8">Temperatura °F =</span>
                    <input
                      type="number"
                      class="form-control form-control-sm col-md-4"
                      v-model="form.temperatura"
                      :class="
                        $v.form.temperatura.$invalid ? 'is-invalid' : 'is-valid'
                      "
                      @blur="validarTemp"
                    />
                  </div>
                  <div
                    class="row justify-content-between mb-2"
                    v-show="form.tipo_liquidacion == 3"
                  >
                    <span class="col-md-6">C.T.L</span>
                    <input
                      type="number"
                      class="form-control form-control-sm col-md-4"
                      v-model="form.ctl"
                      :class="$v.form.ctl.$invalid ? 'is-invalid' : 'is-valid'"
                    />
                  </div>
                  <!-- Psi -->
                  <div
                    class="row justify-content-between mb-2"
                    v-show="form.tipo_liquidacion != 3"
                  >
                    <span class="col-md-8">Pressure, PSI=</span>
                    <input
                      type="number"
                      class="form-control form-control-sm col-md-4"
                      v-model="form.presion"
                      :class="
                        $v.form.presion.$invalid ? 'is-invalid' : 'is-valid'
                      "
                    />
                  </div>
                  <!-- Gov Inicial / Final -->
                  <div
                    class="row justify-content-between mb-2"
                    v-show="form.tipo_liquidacion == 2"
                  >
                    <span class="col-md-4">GOV</span>
                    <div class="input-group col-md-4">
                      <div class="input-group-prepend">
                        <div class="input-group-text">Ini</div>
                      </div>
                      <input
                        type="number"
                        class="form-control form-control-sm"
                        v-model="form.gov_ini"
                        :class="
                          $v.form.gov_ini.$invalid ? 'is-invalid' : 'is-valid'
                        "
                        @blur="validarTemp"
                      />
                    </div>

                    <div class="input-group col-md-4">
                      <div class="input-group-prepend">
                        <div class="input-group-text">Fin</div>
                      </div>
                      <input
                        type="number"
                        class="form-control form-control-sm"
                        v-model="form.gov_fin"
                        :class="
                          $v.form.gov_fin.$invalid ? 'is-invalid' : 'is-valid'
                        "
                        @blur="validarTemp"
                      />
                    </div>
                  </div>
                  <!-- Gov -->
                  <div
                    class="row justify-content-between mb-2"
                    v-show="form.tipo_liquidacion != 2"
                  >
                    <span class="col-md-8">G.O.V</span>
                    <input
                      type="number"
                      class="form-control form-control-sm col-md-4"
                      v-model="form.gov"
                      :class="$v.form.gov.$invalid ? 'is-invalid' : 'is-valid'"
                      @blur="validarGov"
                    />
                  </div>
                  <!-- Bsw -->
                  <div class="row justify-content-between mb-2">
                    <span class="col-md-8">B.S.W</span>
                    <input
                      type="number"
                      class="form-control form-control-sm col-md-4"
                      v-model="form.bsw"
                      :class="$v.form.bsw.$invalid ? 'is-invalid' : 'is-valid'"
                      @blur="validarBsw"
                    />
                  </div>
                  <!-- Gsv -->
                  <div class="row justify-content-between mb-2">
                    <span class="col-md-8">G.S.V</span>
                    <input
                      type="number"
                      class="form-control form-control-sm col-md-4"
                      v-model="form.gsv"
                      :class="[
                        $v.form.gsv.$invalid ||
                        parseFloat(form.gsv) !== parseFloat(form.gsv_calc) ||
                        parseFloat(form.gsv) >
                          parseFloat(viaje.volumen_calculado)
                          ? 'is-invalid'
                          : 'is-valid',
                      ]"
                    />
                  </div>
                  <div class="row justify-content-between mb-2">
                    <span
                      class="badge alert-default-danger col-md-12"
                      v-show="form.gsv"
                      v-if="
                        parseFloat(form.gsv) >
                          parseFloat(viaje.volumen_calculado)
                      "
                      >Valor incorrecto Maximo Barriles a Cargar:
                      {{ viaje.volumen_calculado }}</span
                    >
                    <!-- <span class="col-md-7"></span> -->
                    <span
                      class="badge alert-default-warning col-md-12"
                      v-show="form.gsv"
                      v-if="form.gsv != form.gsv_calc"
                      >Valor incorrecto, GSV calculado:
                      {{ form.gsv_calc }}</span
                    >
                    <span
                      class="badge alert-default-success col-md-12"
                      v-show="form.gsv"
                      v-else
                      >Valor correcto, GSV calculado: {{ form.gsv_calc }}</span
                    >
                  </div>
                  <!-- Nsv -->
                  <div class="row justify-content-between mb-2">
                    <span class="col-md-8">N.S.V</span>
                    <input
                      type="number"
                      class="form-control form-control-sm col-md-4"
                      v-model="form.nsv"
                      :class="[
                        $v.form.nsv.$invalid
                          ? 'is-invalid'
                          : 'is-valid' && form.nsv != form.nsv_calc
                          ? 'is-invalid'
                          : 'is-valid',
                      ]"
                    />
                  </div>
                  <div class="row justify-content-between mb-2">
                    <!-- <span class="col-md-7"></span> -->
                    <span
                      class="badge alert-default-warning col-md-12"
                      v-show="form.nsv"
                      v-if="form.nsv != form.nsv_calc"
                      >Valor incorrecto, nsv calculado:
                      {{ form.nsv_calc }}</span
                    >
                    <span
                      class="badge alert-default-success col-md-12"
                      v-show="form.nsv"
                      v-else
                      >Valor correcto, nsv calculado: {{ form.nsv_calc }}</span
                    >
                  </div>

                  <!-- Gsv Inicial / Gsv Final -->
                  <div
                    class="row justify-content-between mb-2"
                    v-show="form.tipo_liquidacion == 2"
                  >
                    <span class="col-md-4">G.S.V</span>
                    <strong class="text-right col-md-4">
                      Ini: {{ form.gsv_ini }}</strong
                    >
                    <strong class="text-right col-md-4">
                      Fin: {{ form.gsv_fin }}</strong
                    >
                  </div>
                  <!-- Gov -->
                  <div
                    class="row justify-content-between mb-2"
                    v-show="form.tipo_liquidacion == 2"
                  >
                    <span class="col-md-6">G.O.V</span>
                    <strong class="text-right col-md-6"> {{ form.gov }}</strong>
                  </div>
                  <!-- Csw -->
                  <div class="row justify-content-between mb-2">
                    <span class="col-md-8">C.S.W</span>
                    <strong class="text-right col-md-4">{{ form.csw }}</strong>
                  </div>
                  <!-- CTL Inicial / Final -->
                  <div
                    class="row justify-content-between mb-2"
                    v-show="form.tipo_liquidacion == 2"
                  >
                    <span class="col-md-4">C.T.L</span>
                    <strong class="text-right col-md-4">
                      Ini: {{ form.ctl_ini }}</strong
                    >
                    <strong class="text-right col-md-4">
                      Fin: {{ form.ctl_fin }}</strong
                    >
                  </div>
                  <!-- Ctl -->
                  <div
                    class="row justify-content-between mb-2"
                    v-show="form.tipo_liquidacion != 3"
                  >
                    <span class="col-md-6">C.T.L</span>
                    <strong class="text-right col-md-6">{{ form.ctl }}</strong>
                  </div>
                  <!-- Azufre -->
                  <div class="row justify-content-between mb-2">
                    <span class="col-md-8">% Azufre</span>
                    <input
                      type="number"
                      class="form-control form-control-sm col-md-4"
                      v-model="form.azufre"
                      :class="
                        $v.form.azufre.$invalid ? 'is-invalid' : 'is-valid'
                      "
                      @blur="validarAzufre"
                    />
                  </div>
                  <!-- Sal -->
                  <div class="row justify-content-between mb-2">
                    <span class="col-md-8">% SAL PTB</span>
                    <input
                      type="number"
                      min="0"
                      max="100"
                      class="form-control form-control-sm col-md-4"
                      v-model="form.sal_ptb"
                      :class="
                        $v.form.sal_ptb.$invalid ? 'is-invalid' : 'is-valid'
                      "
                    />
                  </div>
                  <!-- Sedimento -->
                  <div
                    class="row justify-content-between mb-2"
                    v-show="form.tipo_liquidacion != 3"
                  >
                    <span class="col-md-8">% Sedimento</span>
                    <input
                      type="number"
                      class="form-control form-control-sm col-md-4"
                      v-model="form.porc_sedimento"
                      :class="
                        $v.form.porc_sedimento.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    />
                  </div>
                  <!-- Rvp -->
                  <div class="row justify-content-between mb-2">
                    <span class="col-md-8">R.V.P</span>
                    <input
                      type="number"
                      class="form-control form-control-sm col-md-4"
                      v-model="form.rvp"
                      :class="$v.form.rvp.$invalid ? 'is-invalid' : 'is-valid'"
                    />
                  </div>
                  <!-- Incertidumbre -->
                  <div class="row justify-content-between mb-2">
                    <span class="col-md-8">% Incertidumbre Expandida U</span>
                    <input
                      type="number"
                      class="form-control form-control-sm col-md-4"
                      v-model="form.iincer_exp_porc"
                      :class="
                        $v.form.iincer_exp_porc.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    />
                  </div>
                  <!-- Incertidumbre Expandida -->
                  <div class="row justify-content-between">
                    <span class="col-md-8"
                      >Incertidumbre Expandida U (Bls)</span
                    >
                    <input
                      type="number"
                      class="form-control form-control-sm col-md-4"
                      v-model="form.iincer_exp_bls"
                      :class="
                        $v.form.iincer_exp_bls.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-lg-6 col-md-12 col-sm-12"
              v-show="form.tipo_liquidacion == 1"
            >
              <div class="row">
                <div class="col-md-12">
                  <table
                    class="table table-bordered table-striped table-hover table-sm"
                    style="font-size: 0.85em"
                  >
                    <thead class="text-center bg-dark">
                      <tr>
                        <th>Producto</th>
                        <th>Density Range <sub>(kg/m<sup>3</sup>)</sub></th>
                        <th>K<sub>0</sub></th>
                        <th>K<sub>1</sub></th>
                        <th>K<sub>2</sub></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="r in rangos" :key="r.numeracion">
                        <td>
                          <strong>{{ r.producto }}</strong>
                        </td>
                        <td>{{ r.rango_densidad }}</td>
                        <td class="text-right">{{ r.k0 }}</td>
                        <td class="text-right">{{ r.k1 }}</td>
                        <td class="text-right">{{ r.k2 }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div
                  v-if="!$v.correccionTemperatura.$invalid"
                  class="col-lg-7 col-md-12"
                >
                  <div class="card card-light">
                    <div class="card-header p-2">
                      <h6 class="card-title" style="font-size: 1.1em">
                        <i class="fas fa-thermometer-three-quarters"></i>
                        Corrección por efecto de la temperatura
                      </h6>
                    </div>
                    <div class="card-body">
                      <div class="row justify-content-between">
                        <strong>tc<sub>,90</sub> =</strong>
                        <span>{{
                          correccionTemperatura.tc
                            ? parseFloat(correccionTemperatura.tc.toFixed(12))
                            : ``
                        }}</span>
                      </div>
                      <div class="row justify-content-between">
                        <strong>Scaled Temperature, τ =</strong>
                        <span>{{
                          correccionTemperatura.st
                            ? parseFloat(correccionTemperatura.st.toFixed(12))
                            : ``
                        }}</span>
                      </div>
                      <div class="row justify-content-between">
                        <strong>Temperature Correction, Δ<sub>t</sub>=</strong>
                        <span>{{
                          correccionTemperatura.tct
                            ? parseFloat(correccionTemperatura.tct.toFixed(12))
                            : ``
                        }}</span>
                      </div>
                      <div class="row justify-content-between">
                        <strong
                          >t correct to IPTS-68 (t*) c<sub>,68</sub>= tc<sub
                            >,90</sub
                          >
                          - Δt=</strong
                        >
                        <span>{{
                          correccionTemperatura.ipts
                            ? parseFloat(correccionTemperatura.ipts.toFixed(12))
                            : ``
                        }}</span>
                      </div>
                      <div class="row justify-content-between">
                        <strong
                          >T corrected to IPTS-68 (t*)=t<sub>F,68</sub>
                          =</strong
                        >
                        <span>{{
                          correccionTemperatura.ipts68
                            ? parseFloat(
                                correccionTemperatura.ipts68.toFixed(12)
                              )
                            : ``
                        }}</span>
                      </div>
                      <div class="row justify-content-between">
                        <strong>Densidad Kg/cu meter, Rho60*=</strong>
                        <span>{{
                          correccionTemperatura.dm
                            ? parseFloat(correccionTemperatura.dm.toFixed(12))
                            : ``
                        }}</span>
                      </div>
                      <div class="row justify-content-between">
                        <strong>Δt=</strong>
                        <span>{{
                          correccionTemperatura.t
                            ? parseFloat(correccionTemperatura.t.toFixed(12))
                            : ``
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="correccionPresion.length > 0"
                  class="col-lg-5 col-md-12"
                >
                  <div class="card card-light">
                    <div class="card-header p-2">
                      <h6 class="card-title" style="font-size: 1.1em">
                        <i class="fas fa-compress"></i>
                        Corrección por efecto de la presión
                      </h6>
                    </div>
                    <div class="card-body p-0">
                      <table
                        class="table table-bordered table-striped table-hover table-sm m-0"
                        style="font-size: 0.85em"
                      >
                        <thead
                          class="text-center bg-dark"
                          style="font-size: 0.9em"
                        >
                          <tr>
                            <th class="col-7">Producto</th>
                            <th>Scaled compressibility factor, (Fp)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="cp in correccionPresion"
                            :key="cp.numeracion"
                          >
                            <td class="p-1">
                              <strong>{{ cp.producto }}</strong>
                            </td>
                            <td class="text-right p-1">
                              {{
                                cp.factor
                                  ? parseFloat(cp.factor.toFixed(3))
                                  : ``
                              }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 table-responsive">
                  <table
                    v-if="ctlCpl.length > 0"
                    class="table table-bordered table-striped table-hover table-sm"
                    style="font-size: 0.85em"
                  >
                    <thead class="text-center bg-dark">
                      <tr>
                        <th>Producto</th>
                        <th>A</th>
                        <th>B</th>
                        <th>(Rho60* a IPTS-68)=ρ*</th>
                        <th>Alfa60</th>
                        <th>CTL</th>
                        <th>CPL</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="cc in ctlCpl" :key="cc.numeracion">
                        <td>
                          <strong>{{ cc.producto }}</strong>
                        </td>
                        <td class="text-right">
                          {{ cc.a ? parseFloat(cc.a.toFixed(12)) : `` }}
                        </td>
                        <td class="text-right">
                          {{ cc.b ? parseFloat(cc.b.toFixed(12)) : `` }}
                        </td>
                        <td class="text-right">
                          {{
                            cc.rho_ipts
                              ? parseFloat(cc.rho_ipts.toFixed(12))
                              : ``
                          }}
                        </td>
                        <td class="text-right">
                          {{ cc.alfa ? parseFloat(cc.alfa.toFixed(12)) : `` }}
                        </td>
                        <td class="text-right">
                          {{ cc.ctl ? parseFloat(cc.ctl.toFixed(5)) : `` }}
                        </td>
                        <td class="text-right">
                          {{ cc.cpl ? parseFloat(cc.cpl.toFixed(5)) : `` }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="
            !$v.form.$invalid &&
              !$v.sellos.$invalid &&
              form.gsv == form.gsv_calc &&
              form.nsv == form.nsv_calc
          "
          class="modal-footer justify-content-between p-2"
        >
          <button type="button" class="btn btn-primary" @click="save()">
            <i class="fas fa-save"></i>
            Guardar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import {
  required,
  minValue,
  maxValue,
  helpers,
} from "vuelidate/lib/validators";
import vSelect from "vue-select";
import moment from "moment";
const timeFormat = helpers.regex(
  "timeFormat",
  /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/
);

export default {
  name: "VentanaOperacionesLiquidacion",

  components: {
    vSelect,
  },

  data() {
    return {
      viaje: {},
      tipo_control: null,
      form: {},
      sellos: [],
      tanques: [],
      tanque: {},
      listasForms: {
        productos: [],
        productos_liquidos: [],
        sellos: [],
        tipos_liquidacion: [],
      },
      rangos: [],
      correccionPresion: [],
      ctlCpl: [],
    };
  },

  computed: {
    correccionTemperatura: function() {
      this.resetData();
      let temp = {
        tc: null,
        tci: null,
        tcf: null,
        st: null,
        sti: null,
        stf: null,
        tct: null,
        tcti: null,
        tctf: null,
        ipts: null,
        iptsi: null,
        iptsf: null,
        ipts68: null,
        ipts68i: null,
        ipts68f: null,
        dm: null,
        t: null,
        ti: null,
        tf: null,
      };
      if (this.form.tipo_liquidacion == 1) {
        if (
          this.form.temperatura &&
          this.form.api &&
          this.form.presion &&
          this.form.slct_producto &&
          this.form.gov &&
          this.form.bsw
        ) {
          temp.tc = (this.form.temperatura - 32) / 1.8;
          temp.st = temp.tc / 630;
          temp.tct =
            (-0.148759 +
              (-0.267408 +
                (1.08076 +
                  (1.269056 +
                    (-4.089591 +
                      (-1.871251 + (7.438081 + -3.536296 * temp.st) * temp.st) *
                        temp.st) *
                      temp.st) *
                    temp.st) *
                  temp.st) *
                temp.st) *
            temp.st;
          temp.ipts = temp.tc - temp.tct;
          temp.ipts68 = temp.ipts * 1.8 + 32;
          temp.dm = (141.5 / (parseFloat(this.form.api) + 131.5)) * 999.016;
          temp.t = temp.ipts68 - 60.0068749;
          this.getDataCtlCpl(temp);
        }
      } else if (this.form.tipo_liquidacion == 2) {
        if (
          this.form.temp_ini &&
          this.form.temp_fin &&
          this.form.api &&
          this.form.presion &&
          this.form.slct_producto &&
          this.form.gov_ini &&
          this.form.gov_fin &&
          this.form.bsw
        ) {
          temp.tci = (this.form.temp_ini - 32) / 1.8;
          temp.tcf = (this.form.temp_fin - 32) / 1.8;

          temp.sti = temp.tci / 630;
          temp.stf = temp.tcf / 630;
          /* Inicial */
          temp.tcti =
            (-0.148759 +
              (-0.267408 +
                (1.08076 +
                  (1.269056 +
                    (-4.089591 +
                      (-1.871251 +
                        (7.438081 + -3.536296 * temp.sti) * temp.sti) *
                        temp.sti) *
                      temp.sti) *
                    temp.sti) *
                  temp.sti) *
                temp.sti) *
            temp.sti;

          /* Final */
          temp.tctf =
            (-0.148759 +
              (-0.267408 +
                (1.08076 +
                  (1.269056 +
                    (-4.089591 +
                      (-1.871251 +
                        (7.438081 + -3.536296 * temp.stf) * temp.stf) *
                        temp.stf) *
                      temp.stf) *
                    temp.stf) *
                  temp.stf) *
                temp.stf) *
            temp.stf;

          temp.iptsi = temp.tci - temp.tcti;
          temp.iptsf = temp.tcf - temp.tctf;
          temp.ipts68i = temp.iptsi * 1.8 + 32;
          temp.ipts68f = temp.iptsf * 1.8 + 32;
          temp.dm = (141.5 / (parseFloat(this.form.api) + 131.5)) * 999.016;
          temp.ti = temp.ipts68i - 60.0068749;
          temp.tf = temp.ipts68f - 60.0068749;
          this.getDataCtlCpDiferencial(temp);
        }
      } else if (this.form.tipo_liquidacion == 3) {
        if (this.form.api && this.form.slct_producto && this.form.bsw) {
          this.getDataCtlCpBascula(null);
        }
      }
      return temp;
    },
  },

  validations() {
    let producto_liquido = {};
    let temp_ini = {};
    let temp_fin = {};
    let gov_ini = {};
    let gov_fin = {};
    let peso_inicial = {};
    let peso_final = {};
    let peso_neto = {};
    let ctl = {};
    let presion = {};
    let porc_sedimento = {};

    if (this.form.slct_producto) {
      if (
        this.form.slct_producto.numeracion == 1 ||
        this.form.slct_producto.numeracion == 5
      ) {
        producto_liquido = {
          required,
        };
      } else {
        producto_liquido = {
          required: false,
        };
      }
    }

    if (this.form.tipo_liquidacion == 1) {
      temp_ini = {
        required: false,
      };
      temp_fin = {
        required: false,
      };
      gov_ini = {
        required: false,
      };
      gov_fin = {
        required: false,
      };
      temp_fin = {
        required: false,
      };
      peso_inicial = {
        required: false,
      };
      peso_final = {
        required: false,
      };
      peso_neto = {
        required: false,
      };
      presion = {
        required: false,
      };
    } else if (this.form.tipo_liquidacion == 2) {
      temp_ini = {
        required,
      };
      temp_fin = {
        required,
      };
      gov_ini = {
        required,
      };
      gov_fin = {
        required,
      };
      peso_inicial = {
        required: false,
      };
      peso_final = {
        required: false,
      };
      peso_neto = {
        required: false,
      };
      ctl = {
        required,
      };
      presion = {
        required,
      };
      porc_sedimento = {
        required,
      };
    } else {
      temp_ini = {
        required: false,
      };
      temp_fin = {
        required: false,
      };
      gov_ini = {
        required: false,
      };
      gov_fin = {
        required: false,
      };
      peso_inicial = {
        required: required,
      };
      peso_final = {
        required: required,
      };
      peso_neto = {
        required: required,
      };
      ctl = {
        required: false,
      };
      porc_sedimento = {
        required: false,
      };
      presion = {
        required: false,
      };
    }
    return {
      form: {
        tipo_liquidacion: {
          required,
        },
        campo: {
          required,
        },
        vehiculo: {
          required,
        },
        remolque: {
          required,
        },
        slct_producto: {
          required,
        },
        tanque_id: {
          required,
        },
        producto_liquido: producto_liquido,
        temp_ini: temp_ini,
        temp_fin: temp_fin,
        gov_ini: gov_ini,
        gov_fin: gov_fin,
        peso_inicial: peso_inicial,
        peso_final: peso_final,
        peso_neto: peso_neto,
        gov: {
          required,
        },
        gsv: {
          required,
        },
        nsv: {
          required,
        },
        presion: presion,
        temperatura: {
          required,
        },
        api: {
          required,
        },
        bsw: {
          required,
        },
        azufre: {
          required,
        },
        sal_ptb: {
          required,
          minValue: minValue(0),
          maxValue: maxValue(100),
        },
        ctl: ctl,
        porc_sedimento: porc_sedimento,
        csw: {
          required,
        },
        rvp: {
          required,
        },
        iincer_exp_porc: {
          required,
        },
        iincer_exp_bls: {
          required,
        },
        fecha_hora_llamado_cargue: {
          required,
        },
        hora_llamado_cargue: {
          timeFormat,
        },
        fecha_hora_inicio_cargue: {
          required,
        },
        hora_inicio_cargue: {
          timeFormat,
        },
      },

      sellos: {
        required,
      },

      correccionTemperatura: {
        tc: {
          required,
        },
        st: {
          required,
        },
        tct: {
          required,
        },
        ipts: {
          required,
        },
        ipts68: {
          required,
        },
        dm: {
          required,
        },
        t: {
          required,
        },
      },
    };
  },

  methods: {
    async getIndex(viaje, tipo_control) {
      this.tipo_control = parseInt(tipo_control);
      clearInterval(this.$parent.$parent.polling);
      await this.getTipoLiquidacion();
      this.$parent.$parent.cargando = true;
      this.viaje = viaje;
      await this.buscarSellos();
      await this.validarTemp();
      await this.validarApi();
      await this.validarBsw();
      await this.validarAzufre();
      await this.validarGov();
      await this.resetForm(this.viaje, this.tipo_control);

      // Se valida la información de la liquidación
      this.$parent.$parent.cargando = false;
    },

    async getProductosHidroarburosLiquidación() {
      this.form.slct_producto = {};
      await axios.get("/api/lista/189").then((response) => {
        //this.listasForms.productos = response.data;
        let respuesta = response.data;
        let lista = [];
        let tipoProlista = [];
        if (this.form.tipo_liquidacion == 3) {
          lista.push({
            id: respuesta[4].id,
            cod_alterno: respuesta[4].cod_alterno,
            numeracion: respuesta[4].numeracion,
            descripcion: respuesta[4].descripcion,
            lista_id: respuesta[4].lista_id,
          });
        } else {
          for (let i = 0; i < respuesta.length; i++) {
            lista.push({
              id: respuesta[i].id,
              cod_alterno: respuesta[i].cod_alterno,
              numeracion: respuesta[i].numeracion,
              descripcion: respuesta[i].descripcion,
              lista_id: respuesta[i].lista_id,
            });
          }
        }
        if (this.viaje.tipo_producto_id) {
          tipoProlista =
            this.viaje.tipo_producto_id == 1
              ? lista.filter((item) => item.cod_alterno == "Crudo")
              : this.viaje.tipo_producto_id == 10
              ? lista.filter((item) => item.cod_alterno == "Derivado")
              : [];
        }

        this.listasForms.productos =
          tipoProlista.length > 0 ? tipoProlista : lista;

        this.form.slct_producto =
          this.form.tipo_liquidacion != 3
            ? this.listasForms.productos[0]
            : null;

        this.getProductoLiquido();
        this.getRangos();
      });
    },

    getRangos() {
      this.rangos = [];
      this.listasForms.productos.forEach((prod) => {
        let rango_densidad = "";
        let k0 = 0;
        let k1 = 0;
        let k2 = 0;
        switch (prod.numeracion) {
          // 1 Crudo
          case 1:
            rango_densidad = `610,6<=Rho60<1163,5`;
            k0 = 341.0957;
            k1 = 0;
            k2 = 0;
            break;

          // 2 Fuel Oils
          case 2:
            rango_densidad = `838,3127<=Rho60<=1163,5`;
            k0 = 103.872;
            k1 = 0.2701;
            k2 = 0;
            break;

          // 3 Jet Fuels
          case 3:
            rango_densidad = `787,5195<=Rho60<838,3127`;
            k0 = 330.301;
            k1 = 0;
            k2 = 0;
            break;

          // 4 Transition Zone
          case 4:
            rango_densidad = `770,3520<=Rho60<787,5195`;
            k0 = 1489.067;
            k1 = 0;
            k2 = -0.0018684;
            break;

          // 5 Gasolines
          case 5:
            rango_densidad = `610,6<=Rho60<770,3520`;
            k0 = 192.4571;
            k1 = 0.2438;
            k2 = 0;
            break;

          // 6 Lubricanting Oil
          case 6:
            rango_densidad = `800,9<=Rho60<1163,5`;
            k0 = 0;
            k1 = 0.34878;
            k2 = 0;
            break;
        }

        this.rangos.push({
          numeracion: prod.numeracion,
          producto: prod.descripcion,
          rango_densidad,
          k0,
          k1,
          k2,
        });
      });
    },

    async resetForm(viaje, tipo_control) {
      // Se hace la validación de las fechas de llamado e inicio de cargue
      let fecha_llamado_cargue = null;
      let hora_llamado_cargue = null;
      let fecha_hora_llamado_cargue = null;
      let fecha_inicio_cargue = null;
      let hora_inicio_cargue = null;
      let fecha_hora_inicio_cargue = null;
      let peso_vh = null;
      let peso_remol = null;
      await this.getProductosHidroarburosLiquidación();
      switch (tipo_control) {
        case 1: // Viajes
          if (viaje.trazas_fechas_viaje) {
            const tfv = viaje.trazas_fechas_viaje;
            const fhlc = tfv.fecha_hora_llamado
              ? moment(tfv.fecha_hora_llamado)
              : null;
            fecha_hora_llamado_cargue = fhlc
              ? fhlc.format("YYYY-MM-DD HH:mm:ss")
              : null;
            fecha_llamado_cargue = fhlc ? fhlc.format("YYYY-MM-DD") : null;
            hora_llamado_cargue = fhlc ? fhlc.format("HH:mm:ss") : null;

            const fhic = tfv.fecha_hora_inicio_cargue
              ? moment(tfv.fecha_hora_inicio_cargue)
              : null;
            fecha_hora_inicio_cargue = fhic
              ? fhic.format("YYYY-MM-DD HH:mm:ss")
              : null;
            fecha_inicio_cargue = fhic ? fhic.format("YYYY-MM-DD") : null;
            hora_inicio_cargue = fhic ? fhic.format("HH:mm:ss") : null;
          }

          peso_vh = viaje.vehiculo ? viaje.vehiculo.peso_vacio : null;
          peso_remol = viaje.remolque ? viaje.remolque.peso_vacio : null;
          this.tanques = viaje.sitio_cargue.tanques
            ? viaje.sitio_cargue.tanques
            : [];
          this.form = {
            viaje_type: "App\\Viaje",
            viaje_id: viaje.id,
            // Datos del viaje
            campo: viaje.sitio ? viaje.sitio.nombre : null,
            vehiculo: viaje.vehiculo ? viaje.vehiculo.placa : null,
            remolque: viaje.remolque ? viaje.remolque.placa : null,
            peso_vehiculo: peso_vh,
            peso_remolque: peso_remol,
            peso_inicial: null,

            peso_final: viaje.liquidacion_guia
              ? viaje.liquidacion_guia.peso_final
              : null,
            peso_neto: viaje.liquidacion_guia
              ? viaje.liquidacion_guia.peso_neto
              : null,
            transportadora: viaje.transportadora
              ? viaje.transportadora.razon_social
              : null,

            tipo_liquidacion: viaje.liquidacion_guia
              ? viaje.liquidacion_guia.tipo_liquidacion
              : viaje.sitio.tipo_liquidacion != null
              ? viaje.sitio.tipo_liquidacion
              : null,

            slct_producto: viaje.liquidacion_guia
              ? this.listasForms.productos.find(
                  (pr) => pr.numeracion === viaje.liquidacion_guia.producto
                )
              : null,

            producto_liquido: viaje.liquidacion_guia
              ? viaje.liquidacion_guia.producto_liquido
              : null,
            // Datos a calcular
            gov: viaje.liquidacion_guia ? viaje.liquidacion_guia.gov : null,
            gov_ini: viaje.liquidacion_guia
              ? viaje.liquidacion_guia.gov_ini
              : null,
            gov_fin: viaje.liquidacion_guia
              ? viaje.liquidacion_guia.gov_fin
              : null,
            gsv: viaje.liquidacion_guia ? viaje.liquidacion_guia.gsv : null,
            gsv_ini: viaje.liquidacion_guia
              ? viaje.liquidacion_guia.gsv_ini
              : null,
            gsv_fin: viaje.liquidacion_guia
              ? viaje.liquidacion_guia.gsv_fin
              : null,
            nsv: viaje.liquidacion_guia ? viaje.liquidacion_guia.nsv : null,
            presion: viaje.liquidacion_guia
              ? viaje.liquidacion_guia.presion
              : null,
            temperatura: viaje.liquidacion_guia
              ? viaje.liquidacion_guia.temperatura
              : null,
            temp_ini: viaje.liquidacion_guia
              ? viaje.liquidacion_guia.temp_ini
              : null,
            temp_fin: viaje.liquidacion_guia
              ? viaje.liquidacion_guia.temp_fin
              : null,
            api: viaje.liquidacion_guia ? viaje.liquidacion_guia.api : null,
            bsw: viaje.liquidacion_guia ? viaje.liquidacion_guia.bsw : null,
            azufre: viaje.liquidacion_guia
              ? viaje.liquidacion_guia.azufre
              : null,
            sal_ptb: viaje.liquidacion_guia
              ? viaje.liquidacion_guia.sal_ptb
              : null,
            ctl: viaje.liquidacion_guia ? viaje.liquidacion_guia.ctl : null,
            ctl_ini: viaje.liquidacion_guia
              ? viaje.liquidacion_guia.ctl_ini
              : null,
            ctl_fin: viaje.liquidacion_guia
              ? viaje.liquidacion_guia.ctl_fin
              : null,
            porc_sedimento: viaje.liquidacion_guia
              ? viaje.liquidacion_guia.porc_sedimento
              : null,
            csw: viaje.liquidacion_guia ? viaje.liquidacion_guia.csw : null,
            rvp: viaje.liquidacion_guia ? viaje.liquidacion_guia.rvp : null,
            iincer_exp_porc: viaje.liquidacion_guia
              ? viaje.liquidacion_guia.iincer_exp_porc
              : null,
            iincer_exp_bls: viaje.liquidacion_guia
              ? viaje.liquidacion_guia.iincer_exp_bls
              : null,

            tanque_id: viaje.liquidacion_guia
              ? viaje.liquidacion_guia.tanque_id
              : null,

            // Datos de fechas
            fecha_llamado_cargue,
            hora_llamado_cargue,
            fecha_hora_llamado_cargue,
            fecha_inicio_cargue,
            hora_inicio_cargue,
            fecha_hora_inicio_cargue,
          };

          this.sellos = [];
          this.tanque = {};
          if (viaje.liquidacion_guia && viaje.liquidacion_guia.sellos) {
            let sellosIds = viaje.liquidacion_guia.sellos.split(",");
            this.sellos = sellosIds
              ? sellosIds.map((id) =>
                  this.listasForms.sellos.find((item) => item.id == id)
                )
              : {};
          }

          break;

        case 3: // Regalias - Compras Sin Transporte
        case 4: // Ventas A Tercero
          if (viaje.fecha_hora_llamado) {
            const fhlc = viaje.fecha_hora_llamado
              ? moment(viaje.fecha_hora_llamado)
              : null;
            fecha_hora_llamado_cargue = fhlc
              ? fhlc.format("YYYY-MM-DD  HH:mm:ss")
              : null;
            fecha_llamado_cargue = fhlc ? fhlc.format("YYYY-MM-DD") : null;
            hora_llamado_cargue = fhlc ? fhlc.format("HH:mm:ss") : null;
          }

          if (viaje.fecha_hora_inicio_cargue) {
            const fhic = viaje.fecha_hora_inicio_cargue
              ? moment(viaje.fecha_hora_inicio_cargue)
              : null;
            fecha_hora_inicio_cargue = fhic
              ? fhic.format("YYYY-MM-DD HH:mm:ss")
              : null;
            fecha_inicio_cargue = fhic ? fhic.format("YYYY-MM-DD") : null;
            hora_inicio_cargue = fhic ? fhic.format("HH:mm:ss") : null;
          }

          this.tanques = viaje.sitio_origen.tanques
            ? viaje.sitio_origen.tanques
            : [];

          peso_vh = viaje.peso_vehiculo ? viaje.peso_vehiculo : null;
          peso_remol = viaje.peso_remolque ? viaje.peso_remolque : null;

          this.form = {
            guia_id: viaje.guia_id,
            viaje_type: "App\\DetGuiaRegalias",
            viaje_id: viaje.id,
            // Datos del viaje
            campo: viaje.sitio ? viaje.sitio.nombre : null,
            vehiculo: viaje.vehiculo,
            remolque: viaje.remolque,
            peso_vehiculo: peso_vh,
            peso_remolque: peso_remol,
            slct_producto:
              viaje.guia &&
              viaje.guia.liquidacion_guia &&
              viaje.guia.liquidacion_guia.producto
                ? this.listasForms.productos.find(
                    (pr) =>
                      pr.numeracion === viaje.guia.liquidacion_guia.producto
                  )
                : null,
            // Datos a calcular
            gov:
              viaje.guia && viaje.guia.liquidacion_guia
                ? viaje.guia.liquidacion_guia.gov
                : viaje.gov,
            gsv:
              viaje.guia && viaje.guia.liquidacion_guia
                ? viaje.guia.liquidacion_guia.gsv
                : viaje.gsv,
            nsv:
              viaje.guia && viaje.guia.liquidacion_guia
                ? viaje.guia.liquidacion_guia.nsv
                : viaje.nsv,
            presion:
              viaje.guia && viaje.guia.liquidacion_guia
                ? viaje.guia.liquidacion_guia.presion
                : viaje.presion,
            temperatura:
              viaje.guia && viaje.guia.liquidacion_guia
                ? viaje.guia.liquidacion_guia.temperatura
                : viaje.temperatura,
            api:
              viaje.guia && viaje.guia.liquidacion_guia
                ? viaje.guia.liquidacion_guia.api
                : null,
            bsw:
              viaje.guia && viaje.guia.liquidacion_guia
                ? viaje.guia.liquidacion_guia.bsw
                : null,
            azufre:
              viaje.guia && viaje.guia.liquidacion_guia
                ? viaje.guia.liquidacion_guia.azufre
                : viaje.azufre,
            sal_ptb:
              viaje.guia && viaje.guia.liquidacion_guia
                ? viaje.guia.liquidacion_guia.sal_ptb
                : null,
            ctl:
              viaje.guia && viaje.guia.liquidacion_guia
                ? viaje.guia.liquidacion_guia.ctl
                : viaje.ctl,
            porc_sedimento:
              viaje.guia && viaje.guia.liquidacion_guia
                ? viaje.guia.liquidacion_guia.porc_sedimento
                : viaje.porc_sedimento,
            csw:
              viaje.guia && viaje.guia.liquidacion_guia
                ? viaje.guia.liquidacion_guia.csw
                : viaje.csw,
            rvp:
              viaje.guia && viaje.guia.liquidacion_guia
                ? viaje.guia.liquidacion_guia.rvp
                : viaje.rvp,
            iincer_exp_porc:
              viaje.guia && viaje.guia.liquidacion_guia
                ? viaje.guia.liquidacion_guia.iincer_exp_porc
                : viaje.iincer_exp_porc,
            iincer_exp_bls:
              viaje.guia && viaje.guia.liquidacion_guia
                ? viaje.guia.liquidacion_guia.iincer_exp_bls
                : viaje.iincer_exp_bls,
            tanque_id: viaje.liquidacion_guia
              ? viaje.liquidacion_guia.tanque_id
              : null,

            // Datos de fechas
            fecha_llamado_cargue,
            hora_llamado_cargue,
            fecha_hora_llamado_cargue,
            fecha_inicio_cargue,
            hora_inicio_cargue,
            fecha_hora_inicio_cargue,
          };
          break;

        default:
          this.tanque = {};
          this.form = {
            // Datos del viaje
            tanque_id: null,
            campo: null,
            vehiculo: null,
            remolque: null,
            peso_inicial: null,
            peso_final: null,
            peso_neto: null,
            transportadora: null,
            slct_producto: null,
            // Datos a calcular
            gov: null,
            gsv: null,
            nsv: null,
            presion: null,
            temperatura: null,
            api: null,
            bsw: null,
            azufre: null,
            sal_ptb: null,
            ctl: null,
            porc_sedimento: null,
            csw: null,
            rvp: null,
            iincer_exp_porc: null,
            iincer_exp_bls: null,
            // Datos de fechas
            fecha_llamado_cargue: null,
            hora_llamado_cargue: null,
            fecha_hora_llamado_cargue: null,
            fecha_inicio_cargue: null,
            hora_inicio_cargue: null,
            fecha_hora_inicio_cargue: null,
          };
          break;
      }

      this.validaFechasCargue();
    },

    resetData() {
      this.correccionPresion = [];
      this.ctlCpl = [];
      // Se resetan los campos calculados del formulario
      this.form.ctl = this.form.ctl ? this.form.ctl : null;
      this.form.gsv = null;
      this.form.bsw = this.form.bsw ? this.form.bsw : null;
      this.form.csw = null;
      this.form.nsv = null;
    },

    getDataCtlCpl(temp) {
      this.listasForms.productos.forEach((p) => {
        let factor = null;
        let a = null;
        let b = null;
        let rho_ipts = null;
        let alfa = null;
        let ctl = null;
        let cpl = null;

        const ran = this.rangos.find(function(r) {
          return r.numeracion === p.numeracion;
        });
        if (ran) {
          a =
            (0.01374979547 / 2) *
            (((ran.k0 / temp.dm + ran.k1) * 1) / temp.dm + ran.k2);
          b =
            (2 * ran.k0 + ran.k1 * temp.dm) /
            (ran.k0 + (ran.k1 + ran.k2 * temp.dm) * temp.dm);
          rho_ipts =
            temp.dm *
            (1 +
              (Math.exp(a * (1 + 0.8 * a)) - 1) / (1 + a * (1 + 1.6 * a) * b));
          alfa = ((ran.k0 / rho_ipts + ran.k1) * 1) / rho_ipts + ran.k2;
          ctl = Math.exp(
            -alfa * temp.t * (1 + 0.8 * alfa * (temp.t + 0.01374979547))
          );
          factor = Math.exp(
            -1.9947 +
              0.00013427 * temp.ipts68 +
              (793920 + 2326 * temp.ipts68) / Math.pow(rho_ipts, 2)
          );
          cpl = 1 / (1 - Math.pow(10, -5) * factor * this.form.presion);
          // Se valida la información del formulario principal
          if (this.form.slct_producto.numeracion === ran.numeracion) {
            this.form.ctl = parseFloat(ctl.toFixed(5));
            this.form.gsv = parseFloat((this.form.gov * ctl).toFixed(2));
            this.form.csw = parseFloat((1 - this.form.bsw / 100).toFixed(5));
            this.form.nsv = parseFloat(
              (this.form.gsv * this.form.csw).toFixed(2)
            );
          }
        }

        this.correccionPresion.push({
          numeracion: p.numeracion,
          producto: p.descripcion,
          factor,
        });

        this.ctlCpl.push({
          numeracion: p.numeracion,
          producto: p.descripcion,
          a,
          b,
          rho_ipts,
          alfa,
          ctl,
          cpl,
        });
      });
    },

    getDataCtlCpDiferencial(temp) {
      this.listasForms.productos.forEach((p) => {
        let factori = null;
        let factorf = null;
        let a = null;
        let b = null;
        let rho_ipts = null;
        let alfa = null;
        let ctli = null;
        let ctlf = null;
        let cpli = null;
        let cplf = null;

        const ran = this.rangos.find(function(r) {
          return r.numeracion === p.numeracion;
        });
        if (ran) {
          a =
            (0.01374979547 / 2) *
            (((ran.k0 / temp.dm + ran.k1) * 1) / temp.dm + ran.k2);
          b =
            (2 * ran.k0 + ran.k1 * temp.dm) /
            (ran.k0 + (ran.k1 + ran.k2 * temp.dm) * temp.dm);
          rho_ipts =
            temp.dm *
            (1 +
              (Math.exp(a * (1 + 0.8 * a)) - 1) / (1 + a * (1 + 1.6 * a) * b));
          alfa = ((ran.k0 / rho_ipts + ran.k1) * 1) / rho_ipts + ran.k2;
          ctli = Math.exp(
            -alfa * temp.ti * (1 + 0.8 * alfa * (temp.ti + 0.01374979547))
          );

          ctlf = Math.exp(
            -alfa * temp.tf * (1 + 0.8 * alfa * (temp.tf + 0.01374979547))
          );
          factori = Math.exp(
            -1.9947 +
              0.00013427 * temp.ipts68i +
              (793920 + 2326 * temp.ipts68i) / Math.pow(rho_ipts, 2)
          );

          factorf = Math.exp(
            -1.9947 +
              0.00013427 * temp.ipts68f +
              (793920 + 2326 * temp.ipts68f) / Math.pow(rho_ipts, 2)
          );
          cpli = 1 / (1 - Math.pow(10, -5) * factori * this.form.presion);
          cplf = 1 / (1 - Math.pow(10, -5) * factorf * this.form.presion);
          // Se valida la información del formulario principal
          if (this.form.slct_producto.numeracion === ran.numeracion) {
            this.form.ctl_ini = parseFloat(ctli.toFixed(5));
            this.form.ctl_fin = parseFloat(ctlf.toFixed(5));

            let gsv_ini = parseFloat(this.form.gov_ini * this.form.ctl_ini);
            let gsv_fin = parseFloat(this.form.gov_fin * this.form.ctl_fin);
            let gsv = Math.abs(gsv_fin - gsv_ini);
            let csw = parseFloat(1 - this.form.bsw / 100);

            this.form.gsv_ini = parseFloat(gsv_ini.toFixed(2));
            this.form.gsv_fin = parseFloat(gsv_fin.toFixed(2));
            this.form.csw = parseFloat(csw.toFixed(5));
            this.form.gsv_calc = parseFloat(gsv.toFixed(2));
            this.form.nsv_calc = parseFloat(gsv * csw).toFixed(2);
            this.form.gov = parseFloat(
              Math.abs(this.form.gov_ini - this.form.gov_fin)
            ).toFixed(2);
            this.form.temperatura = this.form.temp_fin;
            this.form.ctl = this.form.ctl_ini;
          }
        }

        this.correccionPresion.push({
          numeracion: p.numeracion,
          producto: p.descripcion,
          factori,
          factorf,
        });

        this.ctlCpl.push({
          numeracion: p.numeracion,
          producto: p.descripcion,
          a,
          b,
          rho_ipts,
          alfa,
          ctli,
          ctlf,
          cpli,
          cplf,
        });
      });
    },

    closeModal() {
      this.$parent.$parent.pollData();
    },

    getDataCtlCpBascula(temp) {
      this.listasForms.productos.forEach((p) => {
        let ctl = null;
        let cpl = null;

        /* const ran = this.rangos.find(function(r) {
          return r.numeracion === p.numeracion;
        });
        if (ran) {
          a =
            (0.01374979547 / 2) *
            (((ran.k0 / temp.dm + ran.k1) * 1) / temp.dm + ran.k2);
          b =
            (2 * ran.k0 + ran.k1 * temp.dm) /
            (ran.k0 + (ran.k1 + ran.k2 * temp.dm) * temp.dm);
          rho_ipts =
            temp.dm *
            (1 +
              (Math.exp(a * (1 + 0.8 * a)) - 1) / (1 + a * (1 + 1.6 * a) * b));
          alfa = ((ran.k0 / rho_ipts + ran.k1) * 1) / rho_ipts + ran.k2;
          ctl = Math.exp(
            -alfa * temp.t * (1 + 0.8 * alfa * (temp.t + 0.01374979547))
          );
          factor = Math.exp(
            -1.9947 +
              0.00013427 * temp.ipts68 +
              (793920 + 2326 * temp.ipts68) / Math.pow(rho_ipts, 2)
          );
          cpl = 1 / (1 - Math.pow(10, -5) * factor * this.form.presion);
          // Se valida la información del formulario principal */
        //if (this.form.slct_producto.numeracion === ran.numeracion) {
        let gsv =
          parseFloat(this.form.peso_neto) /
          ((141.3819577 / (parseFloat(this.form.api) + 131.5) -
            0.001199407795) *
            158.9872949);
        this.form.gov = parseFloat(gsv / this.form.ctl);
        this.form.gsv_calc = parseFloat(gsv).toFixed(2);
        this.form.csw = parseFloat((1 - this.form.bsw / 100).toFixed(5));
        this.form.nsv_calc = parseFloat(
          (this.form.gsv_calc * this.form.csw).toFixed(2)
        );
        //}
        // }
      });
    },

    pesoNeto() {
      if (this.form.peso_inicial && this.form.peso_final) {
        this.form.peso_neto = parseFloat(
          this.form.peso_final - this.form.peso_inicial
        );
      }
    },

    getFechaHoraCargue() {
      this.form.fecha_hora_llamado_cargue =
        this.form.fecha_llamado_cargue &&
        this.form.hora_llamado_cargue &&
        !this.$v.form.hora_llamado_cargue.$invalid
          ? `${this.form.fecha_llamado_cargue} ${this.form.hora_llamado_cargue}`
          : null;
      this.validaFechasCargue();
    },

    getFechaHoraInicioCargue() {
      this.form.fecha_hora_inicio_cargue =
        this.form.fecha_inicio_cargue &&
        this.form.hora_inicio_cargue &&
        !this.$v.form.hora_inicio_cargue.$invalid
          ? `${this.form.fecha_inicio_cargue} ${this.form.hora_inicio_cargue}`
          : null;
      this.validaFechasCargue();
    },

    validaFechasCargue() {
      this.form.espera_cargue = null;

      if (
        !this.validaFechas(
          this.form.fecha_hora_llamado_cargue,
          this.form.fecha_hora_inicio_cargue
        )
      ) {
        this.limpiarFecha(
          "La fecha de Llamado a Cargue no puede ser Mayor a la fecha Inicio de Cargue",
          "inicio"
        );
      } else {
        if (
          this.viaje.trazas_fechas_viaje &&
          !this.validaFechas(
            this.viaje.trazas_fechas_viaje.fecha_enturne_cargue,
            this.form.fecha_hora_llamado_cargue
          )
        ) {
          this.limpiarFecha(
            `La fecha de Llamado a Cargue no puede ser Menor a la fecha de Enturne: ${this.viaje.trazas_fechas_viaje.fecha_enturne_cargue}`,
            "llamado"
          );
        } else {
          if (
            this.viaje.trazas_fechas_viaje &&
            !this.validaFechas(
              this.viaje.trazas_fechas_viaje.fecha_solicitud,
              this.form.fecha_hora_llamado_cargue
            )
          ) {
            this.limpiarFecha(
              `La fecha de Llamado a Cargue no puede ser Menor a la fecha de Postulación : ${this.viaje.trazas_fechas_viaje.fecha_solicitud}`,
              "llamado"
            );
          } else {
            const inicio = moment(this.form.fecha_hora_llamado_cargue);
            const fin = moment(this.form.fecha_hora_inicio_cargue);
            this.form.espera_cargue = fin.diff(inicio, "hours");
          }
        }
      }
    },

    limpiarFecha(mensaje, cargue) {
      if (cargue == "llamado") {
        this.form.fecha_llamado_cargue = null;
        this.form.hora_llamado_cargue = null;
        this.form.fecha_hora_llamado_cargue = null;
      }
      if (cargue == "inicio") {
        this.form.fecha_inicio_cargue = null;
        this.form.hora_inicio_cargue = null;
        this.form.fecha_hora_inicio_cargue = null;
      }

      this.$swal({
        icon: "error",
        title: mensaje,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3500,
        timerProgressBar: true,
      });
    },

    validaFechas(fecha_menor, fecha_mayor) {
      if (fecha_menor && fecha_mayor) {
        fecha_menor = new Date(fecha_menor);
        fecha_mayor = new Date(fecha_mayor);
        // Se valida que la fecha inicial sea menor que la fecha final
        return fecha_menor > fecha_mayor ? false : true;
      }
      return true;
    },

    async validarTemp() {
      if (
        this.form.temperatura &&
        (this.form.temperatura > 250 || this.form.temperatura < 50)
      ) {
        await this.$swal({
          text:
            "La temperatura debe estar entre 50°C y 250°C. Favor validar la información!!",
          icon: "warning",
          confirmButtonText: "Aceptar!",
        });
        this.form.temperatura = null;
      }
    },

    async validarApi() {
      if (this.form.slct_producto) {
        switch (this.form.slct_producto.numeracion) {
          //Crudo
          case 1:
            if (this.form.api && (this.form.api > 60 || this.form.api < 8)) {
              await this.$swal({
                text:
                  "El A.P.I debe estar entre 8 y 60. Favor validar la información!!",
                icon: "warning",
                confirmButtonText: "Aceptar!",
              });
              this.form.api = null;
            }
            break;
          //Fuel Oils
          case 2:
            if (this.form.api && (this.form.api > 35 || this.form.api < 30)) {
              await this.$swal({
                text:
                  "El A.P.I debe estar entre 30 y 35. Favor validar la información!!",
                icon: "warning",
                confirmButtonText: "Aceptar!",
              });
              this.form.api = null;
            }
            break;
          //Jet Fuels
          case 3:
            if (this.form.api && (this.form.api > 60 || this.form.api < 40)) {
              await this.$swal({
                text:
                  "El A.P.I debe estar entre 40 y 60. Favor validar la información!!",
                icon: "warning",
                confirmButtonText: "Aceptar!",
              });
              this.form.api = null;
            }
            break;
          //Transition Zone
          case 4:
            if (this.form.api && (this.form.api > 40 || this.form.api < 35)) {
              await this.$swal({
                text:
                  "El A.P.I debe estar entre 35 y 40. Favor validar la información!!",
                icon: "warning",
                confirmButtonText: "Aceptar!",
              });
              this.form.api = null;
            }
            break;
          //Gasolines
          case 5:
            if (this.form.api && (this.form.api > 85 || this.form.api < 60)) {
              await this.$swal({
                text:
                  "El A.P.I debe estar entre 60 y 85. Favor validar la información!!",
                icon: "warning",
                confirmButtonText: "Aceptar!",
              });
              this.form.api = null;
            }
            break;
          //Lubricanting Oil
          case 6:
            if (this.form.api && (this.form.api > 20 || this.form.api < 10)) {
              await this.$swal({
                text:
                  "El A.P.I debe estar entre 10 y 20. Favor validar la información!!",
                icon: "warning",
                confirmButtonText: "Aceptar!",
              });
              this.form.api = null;
            }
            break;
          default:
            break;
        }
      } else {
        await this.$swal({
          text: "Favor Seleccionar un Producto!!",
          icon: "warning",
          confirmButtonText: "Aceptar!",
        });
        this.form.api = null;
      }
      /* if (this.form.api && (this.form.api > 95 || this.form.api < 6)) {
        this.$swal({
          text:
            "El A.P.I debe estar entre 6 y 95. Favor validar la información!!",
          icon: "warning",
          confirmButtonText: "Aceptar!",
        });
        this.form.api = null;
      } */
    },

    async validarBsw() {
      if (this.form.bsw > 0.5) {
        await this.$swal({
          text: "Revisar el producto puede estar fuera de especificación!!",
          icon: "warning",
          confirmButtonText: "Aceptar!",
        });
      }
      // this.validarVolumenes();
    },

    async validarAzufre() {
      if (this.form.azufre > 100 || this.form.azufre < 0) {
        await this.$swal({
          text:
            "El valor del porcentaje de Azufre debe estar entre 0 y 100. Favor validar la información!!",
          icon: "warning",
          confirmButtonText: "Aceptar!",
        });
        this.form.azufre = null;
      }
    },

    async validarGov() {
      if (this.form.gov > 300 || this.form.gov < 0) {
        await this.$swal({
          text:
            "El valor del GOV debe estar entre 0 y 300. Favor validar la información!!",
          icon: "warning",
          confirmButtonText: "Aceptar!",
        });
        this.form.gov = null;
      }
    },

    save() {
      if (!this.$v.form.$invalid) {
        /* this.$parent.$parent.cargando = true; */
        this.form.producto = this.form.slct_producto.numeracion;
        this.form.sellos = this.sellos.map((sello) => sello.id).join(",");
        this.form.producto_liquido_id = this.form.producto_liquido.id;
        this.form.user_id = this.$store.state.user.id;
        this.$swal({
          title: "Esta seguro de guardar esta liquidación?",
          text: "Los cambios no se pueden revertir!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, Guardar!",
        }).then((result) => {
          if (result.value) {
            axios({
              method: "POST",
              url: "/api/hidrocarburos/liquidacionGuias",
              data: this.form,
            })
              .then(() => {
                this.$refs.closeModalLiquidacion.click();
                this.$parent.$parent.getIndex();
                this.$swal({
                  icon: "success",
                  title: "La liquidación se guardó exitosamente...",
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
              })
              .catch((e) => {
                this.$swal({
                  icon: "error",
                  title: "Ocurrió un error, vuelva a intentarlo... " + e,
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
              });
          }
        });
      }
    },

    async buscarSellos() {
      let me = this;
      me.$parent.$parent.cargando = true;
      let url = "api/hidrocarburos/sellos/lista";
      await axios
        .get(url, {
          params: {
            viaje_id: me.viaje.id,
            sitio_id:
              me.tipo_control == 1
                ? me.viaje.sitio_cargue_id
                : me.viaje.sitio_origen_id,
          },
        })
        .then(function(response) {
          me.listasForms.sellos = response.data;
          me.$parent.$parent.cargando = false;
        })
        .catch(function(error) {
          me.$swal({
            icon: "error",
            title: "Ocurrió un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          me.$parent.$parent.cargando = false;
        });
    },

    getProductoLiquido() {
      let me = this;
      var url = "api/hidrocarburos/Productos/lista";
      axios
        .get(url, {
          params: {
            sitio_id: this.viaje.sitio_cargue_id,
          },
        })
        .then(function(response) {
          me.listasForms.productos_liquidos = response.data;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    llenarValoresProducto() {
      this.form.api = null;
      this.form.azufre = null;
      this.form.bsw = null;
      this.form.sal_ptb = null;
      this.form.porc_sedimento = null;
      this.form.rvp = null;
      this.form.iincer_exp_bls = null;
      this.form.iincer_exp_porc = null;

      if (this.form.producto_liquido) {
        //this.form.api = this.form.producto_liquido.api;
        this.form.azufre = this.form.producto_liquido.azufre;
        //this.form.bsw = this.form.producto_liquido.bsw;
        this.form.sal_ptb = this.form.producto_liquido.sal_ptb;
        this.form.porc_sedimento = this.form.producto_liquido.sedimento;
        this.form.rvp = this.form.producto_liquido.rvp;
        this.form.iincer_exp_bls = this.form.producto_liquido.bls_incertidumbre_exp;
        this.form.iincer_exp_porc = this.form.producto_liquido.porc_incertidumbre_exp;
      }
    },

    async getTipoLiquidacion() {
      await axios.get("/api/lista/200").then((response) => {
        let data =
          this.$parent.$parent.filtros.sitio_id === 25
            ? response.data.filter((elemento) => elemento.numeracion === 3)
            : response.data.filter(
                (elemento) =>
                  elemento.numeracion ===
                  this.$parent.$parent.slct_sitio.tipo_liquidacion
              );
        this.listasForms.tipos_liquidacion = data;
      });
    },

    selectTanque() {
      this.form.tanque_id = null;
      if (this.tanque) {
        this.form.tanque_id = this.tanque.id;
      }
    },
  },

  beforeMount() {
    this.getTipoLiquidacion();
  },
};
</script>
